<template>
  <div
    :id="componentId"
    ref="componentCard"
    class="component-card"
    data-testid="component-card"
  >
    <div
      :class="['component-and-code', { 'has-preview': preview, 'has-interactive-props': interactivePropsEnabled }]"
      data-testid="component-and-code"
    >
      <div
        v-if="preview"
        class="component-example"
        data-testid="component-example"
      >
        <component
          :is="pascalCase(name)"
          v-model="vModel"
          :class="componentClass"
          v-bind="fullProps"
        >
          <MDCSlot v-if="$slots.default"
            name="default"
          />

          <template
            v-for="slot in Object.keys(slots || {})"
            :key="slot"
            #[slot]
          >
            <MDCSlot
              :name="slot"
              unwrap="p"
            />
          </template>
        </component>
      </div>

      <div
        v-if="interactivePropsEnabled"
        class="component-card-toolbar"
        data-testid="component-card-toolbar"
      >
        <div class="left" />
        <div class="right">
          <KButton
            appearance="tertiary"
            :aria-label="t('labels.customize_component_props')"
            class="edit-interactive-props-button"
            data-testid="edit-interactive-props-button"
            size="small"
            @click="toggleInteractiveProps(true)"
          >
            <EditIcon decorative />
            {{ t('labels.customize_component_props') }}
          </KButton>
        </div>
      </div>

      <div
        v-if="ast || $slots.code"
        :class="['code-example', { 'has-preview': preview, 'has-interactive-props': interactivePropsEnabled }]"
        data-testid="code-example"
      >
        <MDCSlot v-if="$slots.code"
          name="code"
        />
        <ContentRenderer
          v-else-if="ast"
          :value="ast"
        />
      </div>
    </div>

    <div
      v-if="interactivePropsEnabled"
      aria-hidden="true"
      :class="['interactive-props-overlay', { active: showInteractiveProps }]"
      data-testid="interactive-props-overlay"
      @click="toggleInteractiveProps(false)"
    />

    <div
      v-if="interactivePropsEnabled && interactiveProps && propsToSelect.length"
      :class="['interactive-props', { active: showInteractiveProps }]"
      data-testid="interactive-props"
    >
      <div
        class="interactive-props-header"
        data-testid="interactive-props-header"
      >
        <div
          class="interactive-props-title"
          data-testid="interactive-props-header"
        >
          <span data-testid="component-name">{{ pascalCase(displayName || name) }}</span> {{ t('labels.component_props') }}
        </div>
        <KButton
          appearance="none"
          :aria-label="t('actions.close')"
          class="edit-interactive-props-close-button"
          data-testid="edit-interactive-props-close-button"
          icon
          @click="toggleInteractiveProps(false)"
        >
          <CloseIcon decorative />
        </KButton>
      </div>
      <div
        class="interactive-props-content"
        data-testid="interactive-props-content"
      >
        <div
          v-for="prop in propsToSelect"
          :key="prop.name"
          :class="['component-prop', { 'has-children': prop.children?.length, expanded: !collapsedParentProps.has(prop.name) }]"
          data-testid="component-prop"
        >
          <div v-if="prop.children?.length">
            <div class="parent-prop-info">
              <div
                :aria-controls="`component-prop-child-container-${prop.name}`"
                :aria-expanded="!collapsedParentProps.has(prop.name)"
                :aria-label="t('actions.toggle_child_properties')"
                class="toggle-parent-prop-button"
                role="button"
                tabindex="0"
                type="button"
                @click="toggleParentProp(prop.name)"
                @keydown.space.prevent="toggleParentProp(prop.name)"
              >
                <div>
                  <KLabel data-testid="parent-prop-info-label">
                    {{ prop.label }}
                  </KLabel>
                  <div class="parent-prop-info-help">
                    {{ prop.description }}
                  </div>
                </div>
                <component
                  :is="!collapsedParentProps.has(prop.name) ? ChevronDownIcon : ChevronRightIcon"
                  decorative
                />
              </div>
            </div>

            <TransitionGroup
              name="expandprop"
              tag="div"
            >
              <div
                v-for="child in prop.children"
                v-show="!collapsedParentProps.has(prop.name)"
                :id="`component-prop-child-container-${prop.name}`"
                :key="`${prop.name}-${child.name}`"
                class="component-prop child"
                data-testid="component-prop-child"
              >
                <ComponentCardField
                  :model-value="componentProps[prop.name][child.name]"
                  :prop="child"
                  @update:model-value="(val: any) => updateComponentProp(child, val, !child.type?.startsWith('boolean') && !child.options?.length)"
                />
              </div>
            </TransitionGroup>
          </div>
          <ComponentCardField
            v-else
            :model-value="componentProps[prop.name]"
            :prop="prop"
            @update:model-value="(val: any) => updateComponentProp(prop, val, !prop.type?.startsWith('boolean') && !prop.options?.length)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { camelCase, pascalCase, kebabCase } from 'scule'
import { useDebounceFn, useMediaQuery, useEventListener } from '@vueuse/core'
import type { SelectItem } from '@kong/kongponents'
import { CloseIcon, EditIcon } from '@kong/icons'
import { KUI_BREAKPOINT_TABLET } from '@kong/design-tokens'
import { ChevronDownIcon, ChevronRightIcon } from '@kong/icons'

/** Example Usage:

::component-card
---
name: 'page-hero'
baseProps:
  title-tag: h2
  title-font-size: 44px
  title-line-height: 48px
props:
  full-width: true
  background-image:
    url: https://i.imgur.com/rU6cP5h.jpeg
    background-size: cover
  background-color: "#010C32"
  color: white
  padding: 100px 20px
  text-align: left
options:
  - name: text-align
    values:
      - left
      - center
      - right
slots:
  title: |
    Learn, code, and build with our developer community
  description: |
    Collaborate. Innovate. Build better solutions together.
  actions: |
    ::k-button{ size="large" to="/getting-started" }
      Get Started
    ::
---
#title
Learn, code, and build with our developer community
#description
Collaborate. Innovate. Build better solutions together.
#actions
  ::k-button{ size="large" to="/getting-started" }
    Get Started
  ::
::

*/

const props = defineProps({
  /** The component name, e.g. `PageHero` */
  name: {
    type: String,
    required: true,
  },
  /** The component name to display in the generated code example if it differs from the `name`, e.g. `ProseImg` would display as `image` */
  displayName: {
    type: String,
    default: '',
  },
  /** A boolean to toggle on/off the interactive component props. */
  interactiveProps: {
    type: Boolean,
    default: true,
  },
  /** A boolean to toggle on/off the component preview. */
  preview: {
    type: Boolean,
    default: true,
  },
  /**
   * The base props to pass to the component.
   * The props passed here _will not_ render interactive controls.
   */
  baseProps: {
    type: Object,
    default: () => ({}),
  },
  /**
   * The interactive props to pass to the component.
   * The props passed here _will_ render interactive controls.
   */
  props: {
    type: Object,
    default: () => ({}),
  },
  /**
   * The slots and their content to render in the generated code example.
   */
  slots: {
    type: Object,
    default: null,
  },
  /**
   * A list of props to exclude from the interactive props and from the generated code example.
   */
  excludedProps: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  /**
   * Interactive options to render in a select dropdown.
   *
   * @example
   * - name: background-color
   *   values:
   *     - black
   *     - white
   */
  options: {
    type: Array as PropType<{ name: string, values: string[] }[]>,
    default: () => [],
  },
  /** Add classes to the component */
  componentClass: {
    type: String,
    default: '',
  },
  ignoreVModel: {
    type: Boolean,
    default: false,
  },
  /** Custom styles to inject into the component */
  styles: {
    type: String,
    default: '',
  },
})

// Inject any custom `props.styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => props.styles), useAttrs().id as string)
const componentCard = useTemplateRef<HTMLDivElement>('componentCard')
const { t } = useI18n()

// Collect the props, forcing all properties to camelCase for consistency
const baseProps = reactive({ ...convertKebabCasePropertiesToCamelCase(props.baseProps) })
const componentProps = reactive({ ...convertKebabCasePropertiesToCamelCase(props.props) })
const fullProps = computed(() => ({ ...baseProps, ...componentProps }))

// Utilize a Set to track the collapsed parent prop sections (they are expanded by default)
const collapsedParentProps = useState(`expanded-parent-props-${componentId}`, () => new Set<string>())
const toggleParentProp = (propName: string): void => {
  if (collapsedParentProps.value.has(propName)) {
    collapsedParentProps.value.delete(propName)
  } else {
    collapsedParentProps.value.add(propName)
  }
}

// Fetch the component meta
const meta = await fetchComponentMeta(props.name)

const vModel = computed({
  get: () => baseProps.modelValue,
  set: (value) => {
    baseProps.modelValue = value
  },
})

/**
 * Updates the component property with the given value.
 *
 * @param {any} prop - The component prop meta.
 * @param {any} val - The new value to be assigned to the property.
 */
const updateComponentPropBase = (prop: any, val: any): void => {
  const newValue = prop?.type === 'number' ? Number(val) : val
  if (prop.parent?.name) {
    componentProps[prop.parent.name][prop.name] = newValue
  } else {
    componentProps[prop.name] = newValue
  }
}

/**
 * Debounced function to update a component property.
 *
 * This function uses a debounce mechanism to delay the execution of the
 * `updateComponentPropBase` function by 500 milliseconds. It helps in
 * reducing the number of times the property update function is called
 * when there are rapid changes to the property value.
 *
 * @param {any} prop - The component prop meta.
 * @param {any} val - The new value to be assigned to the property.
 * @returns {void}
 */
const updateComponentPropDebounced = useDebounceFn((prop: any, val: any): void => updateComponentPropBase(prop, val), 500)

/**
 * Updates a component property with the given value. The update can be debounced.
 *
 * @param {any} prop - The component prop meta.
 * @param {any} val - The new value to be assigned to the property.
 * @param {boolean} [debounce=true] - Whether to debounce the update. Defaults to true.
 * @returns {void}
 */
const updateComponentProp = (prop: any, val: any, debounce = true): void => {
  if (debounce) {
    updateComponentPropDebounced(prop, val)
  } else {
    updateComponentPropBase(prop, val)
  }
}

const generateOptions = (key: string): SelectItem[] | null => {
  const optionItem = props?.options?.find(item => camelCase(item?.name) === camelCase(key)) || null

  // If no options are passed, return an empty array
  if (!optionItem) {
    return null
  }

  return optionItem.values.map(option => ({
    label: option,
    value: option,
  }))
}

const propsToSelect = computed((): ComponentCardPropField[] => {
  const generatePropFields: any = (propKey: string, prop: any, parentPropName: string = '') => {
    // Check if prop is excluded
    if (props.excludedProps.map((p) => camelCase(p)).includes(camelCase(parentPropName)) || props.excludedProps.map((p) => camelCase(p)).includes(camelCase(propKey))) {
      return []
    }

    const options: SelectItem[] | null = generateOptions(propKey)
    const fields: ComponentCardPropField[] = []

    const childProps: any[] = []
    const childPropArray = Object.keys(prop.schema?.schema || {})
    childPropArray.forEach((childKey) => {
      const childProp = prop.schema.schema[childKey]
      if (childProp.kind === 'object') {
        childProps.push(prop.schema.schema[childKey].schema)
      }
    })

    // Handle nested object props if the prop has child props
    if (childProps.length) {
      for (const childKey in childProps) {
        const childPropSchema = childProps[childKey]
        for (const [childPropKey, childPropVal] of Object.entries(childPropSchema)) {
          // Only add the child prop if the props.props parent prop has the key
          if (componentProps[propKey] && camelCase(childPropKey) in componentProps[propKey]) {
            const parentProp = fields.find((f) => f.name === propKey)
            // Recursive call to generate the neseted child props by passing the `propKey` as the parentPropName to handle nested object props.
            const newChildProp = generatePropFields(childPropKey, childPropVal, propKey)

            // If the parentProp already exists, add the child prop to the parent prop's existing children array
            if (parentProp) {
              // Be sure to spread the children array to avoid mutating the original array
              parentProp.children?.push(...newChildProp)
            } else {
              // Otherwise, create a new parent prop with the child prop as the first child
              fields.push({
                type: prop.type || 'string',
                name: propKey,
                description: prop.description || '',
                label: propKey === 'modelValue' ? 'value' : kebabCase(propKey),
                options,
                required: prop.required || false,
                parent: parentPropName ? { name: parentPropName } : null,
                children: [...newChildProp],
              })
            }
          }
        }
      }
    } else {
      // No child props, add the prop to the fields array (this could include nested props themselves)
      fields.push({
        type: prop.type || 'string',
        name: propKey,
        description: prop.description || '',
        label: propKey === 'modelValue' ? 'value' : kebabCase(propKey),
        options,
        required: prop.required || false,
        parent: parentPropName ? { name: parentPropName } : null,
        // Nested props
        children: null, // This prop has no children
      })
    }

    return fields
  }

  const parsedComponentProps = componentProps ? Object.keys(componentProps).map((key) => {
    const prop = meta?.meta?.props?.find((p: any) => camelCase(p.name) === camelCase(key))
    return prop ? generatePropFields(key, prop) : []
  }) : []

  return parsedComponentProps.flat().filter(Boolean)
})

// Render computed code example
const code = computed(() => generateCodeFromProps({
  name: props.displayName || props.name,
  props: fullProps.value,
  slots: props.slots,
  excludedProps: props.excludedProps,
  ignoreVModel: props.ignoreVModel,
}))

const isTabletViewport = useMediaQuery(`(min-width: ${KUI_BREAKPOINT_TABLET})`)
const interactivePropsEnabled = computed((): boolean => props.interactiveProps && !!Object.keys(props.props)?.length && !!propsToSelect.value.length)
const showInteractiveProps = useState<boolean>(`${componentId}-show-interactive-props`, () => false)
const toggleInteractiveProps = (visible: boolean) => {
  if (!interactivePropsEnabled.value) {
    return
  }

  // Toggle visibility of the interactive props
  showInteractiveProps.value = visible
  if (visible && !isTabletViewport.value && typeof window !== 'undefined') {
    // Scroll to the top of the component card when the interactive props are shown
    window?.scrollTo({ top: (componentCard.value?.offsetTop || 0) - 100, behavior: 'smooth' })
  }
}

// Parse the markdown code into AST to render the code example
const { data: ast } = await useAsyncData(`${props.name}-${componentId}-ast-${JSON.stringify({ props: componentProps, slots: props.slots })}`, async () => parseMarkdown(code.value), { watch: [code] })

if (interactivePropsEnabled.value) {
  // Reset the interactive props when the window is resized
  useEventListener(window, 'resize', () => {
    showInteractiveProps.value = false
  })
}
</script>

<style lang="scss" scoped>
$interactive-props-breakpoint: #{$kui-breakpoint-laptop};
$interactive-props-max-width: 360px;
$code-example-max-height: 400px;

.component-card {
  align-items: stretch;
  border: $kui-border-width-10 solid $kui-color-border;
  border-radius: $kui-border-radius-30;
  display: flex;
  flex-direction: row;
  gap: $kui-space-0;
  justify-content: flex-start;
  margin: $kui-space-70 $kui-space-0;
  overflow: hidden;
  position: relative;
}

.component-and-code {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;
  width: 100%;

  &.has-interactive-props {
    min-height: 320px;

    @media (min-width: $interactive-props-breakpoint) {
      max-width: calc(100% - $interactive-props-max-width);
    }
  }
}

.interactive-props-overlay {
  backdrop-filter: blur(2px);
  background-color: rgba($kui-color-background-inverse, 0.5);
  border-radius: $kui-border-radius-30;
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  &.active {
    display: block;

    @media (min-width: $interactive-props-breakpoint) {
      display: none !important;
    }
  }
}

.interactive-props {
  background-color: $kui-color-background-neutral-weakest;
  border-bottom-left-radius: $kui-border-radius-0;
  border-bottom-right-radius: $kui-border-radius-30;
  border-left: $kui-border-width-0 solid $kui-color-border;
  border-top-left-radius: $kui-border-radius-0;
  border-top-right-radius: $kui-border-radius-30;
  bottom: 0; // Extend to the height of .component-and-code
  display: flex;
  flex: 0 0 $interactive-props-max-width;
  flex-direction: column;
  margin: $kui-space-0;
  max-height: 100%; // Enable scrolling
  max-width: $interactive-props-max-width; // Fixed width
  overflow-y: auto; // Enable scrolling
  padding: $kui-space-0;
  position: absolute;
  /* stylelint-disable-next-line @kong/design-tokens/use-proper-token */
  right: calc(-1 * ($kui-space-70 + $interactive-props-max-width));
  top: 0;
  transition: right 0.2s ease-in-out;
  width: 100%;

  &.active {
    right: 0;
    z-index: 2;
  }

  @media (min-width: $interactive-props-breakpoint) {
    border-width: $kui-border-width-10;
    right: 0;
    transition: none;
  }

  .interactive-props-header {
    align-items: center;
    background-color: $kui-color-background-neutral-weaker;
    border-bottom: $kui-border-width-10 solid $kui-color-border-neutral-weaker;
    display: flex;
    justify-content: space-between;
    padding: $kui-space-40 $kui-space-50 $kui-space-40 $kui-space-70;
    width: 100%;
  }

  .edit-interactive-props-close-button {
    color: $kui-color-text;

    @media (min-width: $interactive-props-breakpoint) {
      display: none;
    }
  }

  .interactive-props-title {
    font-size: $kui-font-size-30;
    font-weight: $kui-font-weight-semibold;
    line-height: $kui-line-height-30;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .interactive-props-content {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}

.parent-prop-info {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: $kui-space-50;
  width: 100%;

  &-help {
    color: $kui-color-text-neutral;
    font-size: $kui-font-size-20;
    line-height: $kui-line-height-20;
  }
}

.toggle-parent-prop-button {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  white-space: unset;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-radius: $kui-border-radius-30;
    box-shadow: $kui-shadow-focus;
  }
}

.component-prop {
  border-bottom: $kui-border-width-10 solid $kui-color-border;
  padding: $kui-space-50;

  &:last-of-type {
    &.has-children.expanded {
      border-bottom: 0;
    }
  }

  &.has-children {
    padding: $kui-space-0;

    &.expanded {
      border-bottom-color: $kui-color-border-neutral-weak;
      border-left: $kui-border-width-30 solid $kui-color-border-neutral-weak;

      .parent-prop-info {
        box-shadow: $kui-shadow;
        position: relative;
        z-index: 1;
      }
    }
  }

  &.child {
    background-color: $kui-color-background-neutral-weaker;
    border-bottom-color: $kui-color-border-neutral-weak;
    padding: $kui-space-50;

    &:last-of-type {
      border-bottom: $kui-border-width-0;
    }

    :deep(.help-text) {
      color: $kui-color-text-neutral-strong;
    }
  }
}


.component-example {
  padding: $kui-space-50;
  position: relative;

  @media (min-width: $interactive-props-breakpoint) {
    padding: $kui-space-70;
  }
}

.edit-interactive-props-button {
  display: flex !important;
  justify-self: flex-end;

  @media (min-width: $interactive-props-breakpoint) {
    display: none !important; // Ensure it does not flash during rendering
  }
}

.component-card-toolbar {
  align-items: center;
  background: $kui-color-background-neutral-weakest;
  display: flex;
  justify-content: space-between;
  padding: $kui-space-40 $kui-space-70;

  @media (min-width: $interactive-props-breakpoint) {
    display: none;
  }

  &.has-preview {
    border-top: $kui-border-width-10 solid $kui-color-border;
  }
}

.code-example {
  border-top: $kui-border-width-10 solid $kui-color-border;
  height: 100%;
  max-height: calc($code-example-max-height - 100px);

  &:not(.has-preview):not(.has-interactive-props) {
    border-top: none;
  }

  @media (min-width: $kui-breakpoint-mobile) {
    max-height: $code-example-max-height;
  }

  @media (min-width: $interactive-props-breakpoint) {
    &:not(.has-preview) {
      border-top: none;
    }
  }

  > div {
    height: 100%;
  }

  // Customize the code block and background color
  :deep(.prose-pre) {
    &,
    pre {
      border: 0;
      border-bottom-left-radius: $kui-border-radius-30;
      border-bottom-right-radius: $kui-border-radius-30;
      border-top-left-radius: $kui-border-radius-0;
      border-top-right-radius: $kui-border-radius-0;
      height: 100%;
      margin: $kui-space-0;
      scrollbar-width: thin;
    }

    .prose-pre-copy-button {
      right: 18px;
    }
  }
}

.expandprop-enter-active,
.expandprop-leave-active {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.expandprop-enter-from,
.expandprop-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.expandprop-enter-to,
.expandprop-leave-from {
  max-height: 1000px; /* Set a maximum height to allow for expansion */
  opacity: 1;
  overflow-y: auto;
}
</style>
