import { kebabCase, camelCase } from 'scule'

interface GenerateCodeFromPropsParams {
  /** The component name to display in the code */
  name: string
  /** The component props */
  props: Record<string, any>
  /** The component slots */
  slots?: Record<string, any>
  /** Props to exclude from the output */
  excludedProps?: string[]
  /** Whether to ignore v-model props */
  ignoreVModel?: boolean
}

/** Remove quotes from a string */
const sanitizeQuotes = (str: string): string => String(str || '').replace(/"/g, '\\"')

/**
 * Converts a given string of `styles` prop into a YAML block with specified indentation.
 *
 * @param {string} str - The string to be converted into YAML format.
 * @param {number} [indentLevel=0] - The level of indentation to apply to the YAML block.
 * @returns {string} - The formatted YAML string with the specified indentation.
 */
const renderStylesAsYaml = (str: string, indentLevel = 0): string => {
  const indent = '  '.repeat(indentLevel)
  return `|\n${indent}${str.replace(/\n/g, `\n${indent}`)}`.trimEnd()
}

/**
 * Recursively renders an object or array as a string.
 *
 * @param {any} obj - The object or array to render.
 * @returns {any} - The rendered string representation of the object or array.
 *
 * The function handles different types of inputs:
 * - If the input is an array, it recursively renders each element and joins them with commas.
 * - If the input is an object, it recursively renders each key-value pair and joins them with commas.
 * - If the input is a string, it wraps the string in single quotes.
 * - For other types, it returns the input as is.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    return `[${obj.map(renderObject).join(', ')}]`
  }

  if (typeof obj === 'object') {
    return `{ ${Object.entries(obj).map(([key, value]) => `${key}: ${renderObject(value)}`).join(', ')} }`
  }

  if (typeof obj === 'string') {
    return `'${obj}'`
  }

  return obj
}

/**
 * Recursively converts a JavaScript object or array into a YAML-formatted string.
 *
 * @param {any} obj - The object or array to convert to YAML.
 * @param {number} [indentLevel=0] - The current level of indentation (used internally for recursion).
 * @returns {string} - The YAML-formatted string representation of the input object or array.
 */
const renderObjectAsYaml = (obj: any, indentLevel = 0): string | null | undefined => {
  const indent = '  '.repeat(indentLevel)

  if (Array.isArray(obj)) {
    return obj.map(item => `${indent}- ${renderObjectAsYaml(item, indentLevel + 1)?.trimStart() || ''}`).join('\n')
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj)
      // Filter out empty values from being generated in the YAML
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, value]) => ![''].includes(String(value || '').trim()))
      .map(([key, value]) => `${indent}${kebabCase(key)}: ${renderObjectAsYaml(value, indentLevel + 1)?.trimStart() || ''}`)
      .join('\n')
  }

  if (typeof obj === 'string') {
    // Return undefined and null as-is
    if (['undefined', 'null'].includes(obj)) {
      return obj
    }
    // If it can be a number, return a number
    if (!isNaN(Number(obj))) {
      return obj
    }
    return `"${sanitizeQuotes(obj)}"`
  }

  return sanitizeQuotes(String(obj))
}

/**
 * Generates a Markdown (MDC) code block that includes frontmatter with component properties
 * and their values, and optionally includes slots if they are provided.
 *
 * The frontmatter is generated based on the component's props, excluding certain props based on
 * the `excludedProps` and `ignoreVModel` properties. The values are formatted appropriately based
 * on their types (boolean, object, or string).
 *
 * Slots are appended to the Markdown code block if they are provided in the `props.slots` object.
 *
 * @returns {string} The generated Markdown code block.
 */
export default function generateCodeFromProps({
  name,
  props = {},
  slots = {},
  excludedProps = [],
  ignoreVModel = false,
}: GenerateCodeFromPropsParams): string {
  // Increment the number of processed keys that are input into the frontmatter the loop below
  let processedKeys: number = 0
  // Determine if any frontmatter has been generated
  let hasFrontmatter: boolean = false

  let code = `\`\`\`mdc\n::${kebabCase(name)}\n`

  for (const [key, value] of Object.entries(props)) {
    if (value === '' || excludedProps.some(p => camelCase(p) === camelCase(key)) || (key === 'modelValue' && ignoreVModel)) {
      continue
    }

    const isModelValue = key === 'modelValue'
    const formattedKey = isModelValue ? 'model-value' : kebabCase(key)
    const objectValueAsYaml = typeof value === 'object' ? renderObjectAsYaml(value, 1) : ''

    let formattedCode: string = ''

    if (typeof value === 'boolean' && !isModelValue) {
      // Boolean values are rendered as keys and the value is not wrapped in quotes
      formattedCode = `: ${value}`
    } else if (typeof value === 'object') {
      if (value === null) {
        // Render null as-is
        formattedCode = `: ${value}`
      } else {
        // If an object and not empty child properties, render as YAML
        formattedCode = objectValueAsYaml ? `: \n${objectValueAsYaml}` : ''
      }
    } else if (key === 'styles') {
      // The `styles` prop is rendered as a YAML block
      formattedCode = `: ${renderStylesAsYaml(sanitizeQuotes(value as string), 1)}`
    } else {
      if (value === 'undefined' || value === 'null') {
        // Render undefined and null as-is
        formattedCode = `: ${value}`
      } else if (!isNaN(Number(value))) {
        // If it can be a number, return a number
        formattedCode = `: ${Number(value)}`
      } else {
        // All other string values are sanitized and wrapped in quotes
        formattedCode = `: "${sanitizeQuotes(value as string)}"`
      }
    }

    if (formattedCode) {
      if (processedKeys === 0) {
        code += '---\n'
      }
      code += `${formattedKey}${formattedCode}\n`
      hasFrontmatter = true
      processedKeys++
    }
  }

  // End of frontmatter
  if (hasFrontmatter) {
    code += '---'
  }

  // Process slots
  if (slots) {
    code += Object.entries(slots)
      .map(([key, value], idx) => {
        if (key === 'default') {
          return `${hasFrontmatter ? '\n' : ''}${value.endsWith('\n') ? value : `${value}\n`}`
        } else {
          return `${idx === 0 && hasFrontmatter ? '\n' : ''}#${key}\n${value.endsWith('\n') ? value : `${value}\n`}`
        }
      }).join('\n')
  }

  code += `${slots ? '' : '\n'}::
\`\`\`
`
  return code
}
